import React, { useCallback, useEffect, useState, useMemo } from 'react';
import ReactFlow, {
    Background,
    Controls,
    MiniMap,
    Node,
    Edge,
    useNodesState,
    useEdgesState,
    Panel,
    useReactFlow,
    ReactFlowProvider,
    Position,
    MarkerType,
    NodeTypes,
    EdgeTypes
} from 'reactflow';
import 'reactflow/dist/style.css';
import dagre from 'dagre';
import { parseMermaidToFlow } from './utils/flowParser';
import { FlowControls } from './components/FlowControls';
import { FlowHeader } from './components/FlowHeader';
import { SubFlowSelector } from './components/SubFlowSelector';
import { FlowDescription } from './components/FlowDescription';
import { useFlowStyles } from './styles/useFlowStyles';
import { Box, Paper, Typography, Switch, FormControlLabel, Tooltip } from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import HierarchyIcon from '@mui/icons-material/AccountTree';

// Color palette for subflows
const SUBFLOW_COLORS = [
    '#F59E0B', // Amber
    '#10B981', // Emerald
    '#3B82F6', // Blue
    '#EC4899', // Pink
    '#8B5CF6', // Violet
    '#F97316', // Orange
    '#14B8A6', // Teal
    '#6366F1', // Indigo
    '#EF4444', // Red
    '#84CC16', // Lime
];

// Node spacing for layout
const NODE_WIDTH = 172;
const NODE_HEIGHT = 36;

interface MainFlowProps {
    mainFlows: Array<{
        id: number;
        header: string;
        description: string;
        flow: string;
        subFlowMapping: Record<string, number>;
    }>;
    subFlows: Array<{
        id: number;
        mainFlowId: number;
        header: string;
        description: string;
        flow: string;
    }>;
}

// Type definitions to handle the reactflow types properly
type FlowNode = Node<any, string | undefined>;
type FlowEdge = Edge<any>;

// Helper function to organize the nodes in a hierarchical layout
const getLayoutedElements = (nodes: FlowNode[], edges: FlowEdge[], direction = 'TB') => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({ rankdir: direction });

    // Add nodes to the graph
    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: NODE_WIDTH, height: NODE_HEIGHT });
    });

    // Add edges to the graph
    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    // Run the layout algorithm
    dagre.layout(dagreGraph);

    // Apply the layout to the nodes
    const layoutedNodes = nodes.map((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);

        return {
            ...node,
            position: {
                x: nodeWithPosition.x - NODE_WIDTH / 2,
                y: nodeWithPosition.y - NODE_HEIGHT / 2,
            },
            // Add source/target handles based on node connections
            sourcePosition: Position.Bottom,
            targetPosition: Position.Top,
        } as FlowNode;
    });

    return {
        nodes: layoutedNodes,
        edges,
    };
};

// Create a wrapper component for ReactFlow
const FlowWithProvider: React.FC<MainFlowProps> = (props) => {
    return (
        <ReactFlowProvider>
            <MainFlowContent {...props} />
        </ReactFlowProvider>
    );
};

// The main content component (renamed from MainFlow)
const MainFlowContent: React.FC<MainFlowProps> = ({ mainFlows, subFlows }) => {
    const styles = useFlowStyles();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [currentFlow, setCurrentFlow] = useState(mainFlows[0]);
    const [currentSubFlow, setCurrentSubFlow] = useState<typeof subFlows[0] | null>(null);
    const [isSubFlow, setIsSubFlow] = useState(false);
    const [showSubFlows, setShowSubFlows] = useState(false);
    const [activeSubFlows, setActiveSubFlows] = useState<Record<number, boolean>>({});
    const [hierarchicalLayout, setHierarchicalLayout] = useState(true);
    const { fitView } = useReactFlow();

    // Get unique subflows for the current main flow
    const availableSubFlows = useMemo(() => {
        if (!currentFlow) return [];

        // Extract unique subflow IDs from the mapping
        const subflowIds = [...new Set(Object.values(currentFlow.subFlowMapping))];

        // Find the corresponding subflow objects
        return subflowIds.map(id => {
            const subflow = subFlows.find(sf => sf.id === id);
            return subflow || null;
        }).filter(Boolean) as typeof subFlows;
    }, [currentFlow, subFlows]);

    // Initialize active subflows state when current flow changes
    useEffect(() => {
        const newActiveSubFlows: Record<number, boolean> = {};
        availableSubFlows.forEach(subflow => {
            newActiveSubFlows[subflow.id] = true;
        });
        setActiveSubFlows(newActiveSubFlows);
    }, [availableSubFlows]);

    // Ensure nodes have expected properties for layout
    const preprocessNodes = (nodes: FlowNode[]): FlowNode[] => {
        return nodes.map(node => ({
            ...node,
            // Add default styles if not present
            style: {
                ...node.style,
                padding: '8px 16px',
                borderRadius: '4px',
                fontSize: '12px',
                fontWeight: 'bold',
                textAlign: 'center',
                border: '1px solid #ddd',
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
            },
            // Make connection points explicit for layout
            sourcePosition: Position.Bottom,
            targetPosition: Position.Top,
            // Ensure data has label property
            data: {
                ...node.data,
                label: node.data.label || 'Node',
            }
        } as FlowNode));
    };

    // Ensure edges have expected properties for hierarchy
    const preprocessEdges = (edges: FlowEdge[]): FlowEdge[] => {
        return edges.map(edge => ({
            ...edge,
            // Add default styles if not present
            style: {
                ...edge.style,
                strokeWidth: 2,
            },
            // Use smoothstep for nicer curves
            type: 'smoothstep',
            // Add marker for arrow
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
                color: edge.style?.stroke || '#555',
            },
            // Add label if not present
            label: edge.label || '',
        } as FlowEdge));
    };

    // Connect orphaned nodes if they have no connections
    const connectOrphanedNodes = (nodes: FlowNode[], edges: FlowEdge[]): { nodes: FlowNode[], edges: FlowEdge[] } => {
        // Check if there are any nodes
        if (nodes.length <= 1) return { nodes, edges };

        // Create sets of nodes that appear in edges
        const sourcesSet = new Set(edges.map(e => e.source));
        const targetsSet = new Set(edges.map(e => e.target));

        // Find nodes that have no outgoing edges (not in sourcesSet)
        const orphanSources = nodes.filter(node => !sourcesSet.has(node.id));

        // Find nodes that have no incoming edges (not in targetsSet)
        const orphanTargets = nodes.filter(node => !targetsSet.has(node.id));

        // Create new edges to connect orphans to the graph
        let newEdges = [...edges];

        // For nodes with no outgoing connections, connect them to nodes with no incoming
        // This creates A→B chains for orphaned nodes
        if (orphanSources.length > 0 && orphanTargets.length > 0) {
            orphanSources.forEach((sourceNode) => {
                // Skip if this node already has outgoing connections
                if (sourcesSet.has(sourceNode.id)) return;

                // Find a suitable target node
                const targetNode = orphanTargets.find(t => t.id !== sourceNode.id);
                if (targetNode) {
                    newEdges.push({
                        id: `auto-edge-${sourceNode.id}-${targetNode.id}`,
                        source: sourceNode.id,
                        target: targetNode.id,
                        type: 'smoothstep',
                        style: { stroke: '#555', strokeWidth: 2, strokeDasharray: '5,5' },
                        markerEnd: {
                            type: MarkerType.ArrowClosed,
                            width: 20,
                            height: 20,
                            color: '#555'
                        },
                    } as FlowEdge);
                }
            });
        }

        // For completely disconnected nodes, chain them
        // This ensures each node connects to the next one
        const completelyDisconnected = nodes.filter(
            node => !sourcesSet.has(node.id) && !targetsSet.has(node.id)
        );

        if (completelyDisconnected.length > 1) {
            for (let i = 0; i < completelyDisconnected.length - 1; i++) {
                newEdges.push({
                    id: `chain-edge-${completelyDisconnected[i].id}-${completelyDisconnected[i + 1].id}`,
                    source: completelyDisconnected[i].id,
                    target: completelyDisconnected[i + 1].id,
                    type: 'smoothstep',
                    style: { stroke: '#777', strokeWidth: 2, strokeDasharray: '5,5' },
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 20,
                        height: 20,
                        color: '#777'
                    },
                } as FlowEdge);
            }
        }

        return { nodes, edges: newEdges };
    };

    const initializeFlow = useCallback((flowData: string, subFlowsData: Array<{ id: number, flow: string }> = []) => {
        // Parse the main flow
        const { nodes: mainNodes, edges: mainEdges } = parseMermaidToFlow(flowData);

        // Initialize with main flow nodes and edges
        let allNodes = preprocessNodes(mainNodes as FlowNode[]);
        let allEdges = preprocessEdges(mainEdges as FlowEdge[]);

        // Connect orphaned nodes in the main flow
        const { edges: connectedEdges } = connectOrphanedNodes(allNodes, allEdges);
        allEdges = connectedEdges;

        // Add subflow nodes and edges if showing subflows
        if (showSubFlows && !isSubFlow && subFlowsData.length > 0) {
            subFlowsData.forEach((subflow, index) => {
                // Only process active subflows
                if (!activeSubFlows[subflow.id]) return;

                const { nodes: subNodes, edges: subEdges } = parseMermaidToFlow(subflow.flow);

                // Assign a color to this subflow based on its index
                const subflowColor = SUBFLOW_COLORS[index % SUBFLOW_COLORS.length];

                // Create unique IDs for subflow nodes and assign colors
                const prefixedNodes = preprocessNodes((subNodes as FlowNode[]).map(node => ({
                    ...node,
                    id: `sf${subflow.id}-${node.id}`,
                    data: {
                        ...node.data,
                        label: node.data.label,
                        subflowId: subflow.id
                    },
                    style: {
                        ...node.style,
                        backgroundColor: subflowColor,
                        borderColor: subflowColor,
                    }
                } as FlowNode)));

                // Update edge references to use the new node IDs
                const prefixedEdges = preprocessEdges((subEdges as FlowEdge[]).map(edge => ({
                    ...edge,
                    id: `sf${subflow.id}-${edge.id}`,
                    source: `sf${subflow.id}-${edge.source}`,
                    target: `sf${subflow.id}-${edge.target}`,
                    style: {
                        ...edge.style,
                        stroke: subflowColor,
                    }
                } as FlowEdge)));

                // Connect any orphaned nodes in the subflow
                const { edges: connectedSubEdges } = connectOrphanedNodes(prefixedNodes, prefixedEdges);

                // Add connection from main node to subflow's first node
                // Find the node in main flow that maps to this subflow
                const parentNodeIds = Object.entries(currentFlow.subFlowMapping)
                    .filter(([_, sfId]) => sfId === subflow.id)
                    .map(([nodeId]) => nodeId);

                if (parentNodeIds.length > 0 && prefixedNodes.length > 0) {
                    // Find an appropriate root node in the subflow to connect to
                    const rootNodes = prefixedNodes.filter(node =>
                        !connectedSubEdges.some(edge => edge.target === node.id)
                    );

                    const targetNode = rootNodes.length > 0 ? rootNodes[0] : prefixedNodes[0];

                    // Create hierarchical edges from each parent node to the subflow
                    parentNodeIds.forEach(parentId => {
                        allEdges.push({
                            id: `hierarchy-${parentId}-${targetNode.id}`,
                            source: parentId,
                            target: targetNode.id,
                            type: 'smoothstep',
                            animated: true,
                            label: 'subflow',
                            labelStyle: { fill: subflowColor, fontWeight: 'bold', fontSize: 10 },
                            style: {
                                stroke: subflowColor,
                                strokeWidth: 2,
                                strokeDasharray: '5,5',
                            },
                            markerEnd: {
                                type: MarkerType.ArrowClosed,
                                width: 20,
                                height: 20,
                                color: subflowColor,
                            },
                        } as FlowEdge);
                    });
                }

                // Add all subflow nodes and edges to the main graph
                allNodes = [...allNodes, ...prefixedNodes];
                allEdges = [...allEdges, ...connectedSubEdges];
            });
        }

        // Apply hierarchical layout if enabled
        if (hierarchicalLayout) {
            const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
                allNodes,
                allEdges
            );
            setNodes(layoutedNodes);
            setEdges(layoutedEdges);
        } else {
            setNodes(allNodes);
            setEdges(allEdges);
        }

        setTimeout(() => fitView({ duration: 800 }), 100);
    }, [setNodes, setEdges, fitView, showSubFlows, isSubFlow, activeSubFlows, currentFlow, hierarchicalLayout]);

    useEffect(() => {
        if (currentSubFlow) {
            // When viewing a subflow, just show that specific subflow
            initializeFlow(currentSubFlow.flow);
            setIsSubFlow(true);
        } else {
            // For main flow, include active subflows if enabled
            const activeSubFlowsData = availableSubFlows
                .filter(sf => activeSubFlows[sf.id])
                .map(sf => ({ id: sf.id, flow: sf.flow }));

            initializeFlow(currentFlow.flow, showSubFlows ? activeSubFlowsData : []);
            setIsSubFlow(false);
        }
    }, [currentFlow, currentSubFlow, initializeFlow, showSubFlows, availableSubFlows, activeSubFlows]);

    const handleNodeClick = useCallback((event: React.MouseEvent, node: Node) => {
        // Handle clicks on main flow nodes that have subflows
        if (!isSubFlow && currentFlow.subFlowMapping[node.id]) {
            const subFlowId = currentFlow.subFlowMapping[node.id];
            const subFlow = subFlows.find(flow => flow.id === subFlowId);
            if (subFlow) {
                setCurrentSubFlow(subFlow);
            }
        }
    }, [currentFlow, subFlows, isSubFlow]);

    const handleBackToMain = useCallback(() => {
        setCurrentSubFlow(null);
        setIsSubFlow(false);
    }, []);

    const toggleShowSubFlows = useCallback(() => {
        setShowSubFlows(prev => !prev);
    }, []);

    const toggleSubFlow = useCallback((subflowId: number) => {
        setActiveSubFlows(prev => ({
            ...prev,
            [subflowId]: !prev[subflowId]
        }));
    }, []);

    const toggleAllSubFlows = useCallback((active: boolean) => {
        const newState: Record<number, boolean> = {};
        availableSubFlows.forEach(subflow => {
            newState[subflow.id] = active;
        });
        setActiveSubFlows(newState);
    }, [availableSubFlows]);

    const toggleHierarchicalLayout = useCallback(() => {
        setHierarchicalLayout(prev => !prev);
    }, []);

    // Check if all subflows are active
    const allSubflowsActive = useMemo(() => {
        return availableSubFlows.length > 0 &&
            availableSubFlows.every(sf => activeSubFlows[sf.id]);
    }, [availableSubFlows, activeSubFlows]);

    return (
        <div style={styles.container}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onNodeClick={handleNodeClick}
                fitView
                fitViewOptions={{ padding: 0.2 }}
                defaultEdgeOptions={{
                    type: 'smoothstep',
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                    },
                }}
            >
                <Background />
                <Controls />
                <MiniMap />

                <Panel position="top-left">
                    <FlowHeader
                        title={isSubFlow ? currentSubFlow?.header || '' : currentFlow.header}
                        onBack={isSubFlow ? handleBackToMain : undefined}
                    />
                </Panel>

                <Panel position="top-right">
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <FlowControls />

                        <Tooltip title="Toggle hierarchical layout">
                            <Paper
                                elevation={2}
                                sx={{
                                    p: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    cursor: 'pointer',
                                    bgcolor: hierarchicalLayout ? 'primary.light' : 'background.paper',
                                    color: hierarchicalLayout ? 'primary.contrastText' : 'text.primary',
                                }}
                                onClick={toggleHierarchicalLayout}
                            >
                                <HierarchyIcon fontSize="small" />
                                <Typography variant="caption" sx={{ ml: 1 }}>
                                    Hierarchical
                                </Typography>
                                <Switch
                                    size="small"
                                    checked={hierarchicalLayout}
                                    onChange={toggleHierarchicalLayout}
                                />
                            </Paper>
                        </Tooltip>
                    </Box>
                </Panel>

                <Panel position="bottom-left">
                    <FlowDescription
                        description={isSubFlow ? currentSubFlow?.description || '' : currentFlow.description}
                    />
                </Panel>

                <Panel position="bottom-right">
                    <SubFlowSelector
                        currentFlow={currentFlow}
                        subFlows={subFlows}
                        onSubFlowSelect={(subFlow) => {
                            if (subFlow) {
                                const fullSubFlow = subFlows.find(flow => flow.id === subFlow.id);
                                if (fullSubFlow) {
                                    setCurrentSubFlow(fullSubFlow);
                                }
                            }
                        }}
                    />
                </Panel>

                {/* Subflow Controls Panel */}
                {!isSubFlow && availableSubFlows.length > 0 && (
                    <Panel position="bottom-left" style={{ bottom: '120px' }}>
                        <Paper
                            elevation={2}
                            style={{
                                padding: '12px',
                                minWidth: '200px',
                                maxHeight: '300px',
                                overflowY: 'auto'
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderBottom: '1px solid #eee',
                                    paddingBottom: '8px',
                                    marginBottom: '4px'
                                }}>
                                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                        <AccountTreeIcon fontSize="small" />
                                        Subflow Controls
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                size="small"
                                                checked={showSubFlows}
                                                onChange={toggleShowSubFlows}
                                            />
                                        }
                                        label="Show"
                                        labelPlacement="start"
                                        sx={{ margin: 0 }}
                                    />
                                </Box>

                                {showSubFlows && (
                                    <>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    size="small"
                                                    checked={allSubflowsActive}
                                                    onChange={() => toggleAllSubFlows(!allSubflowsActive)}
                                                />
                                            }
                                            label="All Subflows"
                                            sx={{ margin: 0, marginLeft: 1 }}
                                        />
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, marginTop: 1 }}>
                                            {availableSubFlows.map((subflow, index) => (
                                                <Box
                                                    key={subflow.id}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between'
                                                    }}
                                                >
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        <Box
                                                            sx={{
                                                                width: 12,
                                                                height: 12,
                                                                borderRadius: '50%',
                                                                backgroundColor: SUBFLOW_COLORS[index % SUBFLOW_COLORS.length]
                                                            }}
                                                        />
                                                        <Typography variant="caption" noWrap sx={{ maxWidth: 140 }}>
                                                            {subflow.header}
                                                        </Typography>
                                                    </Box>
                                                    <Switch
                                                        size="small"
                                                        checked={!!activeSubFlows[subflow.id]}
                                                        onChange={() => toggleSubFlow(subflow.id)}
                                                    />
                                                </Box>
                                            ))}
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Paper>
                    </Panel>
                )}
            </ReactFlow>
        </div>
    );
};

// Export the provider-wrapped component
export const MainFlow = FlowWithProvider; 