import { useTheme } from '@mui/material/styles';

export const useFlowStyles = () => {
    const theme = useTheme();

    return {
        container: {
            width: '100%',
            height: '100vh',
            backgroundColor: theme.palette.background.default,
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            padding: 1,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: 1,
            boxShadow: 1,
        },
        backButton: {
            color: theme.palette.text.primary,
        },
        headerTitle: {
            fontWeight: 600,
            color: theme.palette.text.primary,
        },
        controls: {
            display: 'flex',
            gap: 0.5,
            padding: 1,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: 1,
            boxShadow: 1,
        },
        controlButton: {
            color: theme.palette.text.primary,
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
        },
        subFlowSelector: {
            padding: 1,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: 1,
            boxShadow: 1,
        },
        subFlowTitle: {
            marginBottom: 1,
            fontWeight: 600,
            color: theme.palette.text.primary,
        },
        subFlowButtons: {
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
        },
        subFlowButton: {
            justifyContent: 'flex-start',
            textAlign: 'left',
            textTransform: 'none',
            color: theme.palette.text.primary,
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
        },
        descriptionContainer: {
            maxWidth: 300,
            padding: 2,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: 1,
        },
        description: {
            color: theme.palette.text.primary,
            lineHeight: 1.5,
        },
    };
}; 