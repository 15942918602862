import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import { useReactFlow } from 'reactflow';
import { useFlowStyles } from '../styles/useFlowStyles';

export const FlowControls: React.FC = () => {
    const styles = useFlowStyles();
    const { zoomIn, zoomOut, fitView } = useReactFlow();

    return (
        <Box sx={styles.controls}>
            <Tooltip title="Zoom In">
                <IconButton
                    onClick={() => zoomIn()}
                    size="small"
                    sx={styles.controlButton}
                >
                    <ZoomInIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Zoom Out">
                <IconButton
                    onClick={() => zoomOut()}
                    size="small"
                    sx={styles.controlButton}
                >
                    <ZoomOutIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Fit View">
                <IconButton
                    onClick={() => fitView({ duration: 800 })}
                    size="small"
                    sx={styles.controlButton}
                >
                    <FitScreenIcon />
                </IconButton>
            </Tooltip>
        </Box>
    );
}; 