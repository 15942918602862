import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useFlowStyles } from '../styles/useFlowStyles';

interface FlowHeaderProps {
    title: string;
    onBack?: () => void;
}

export const FlowHeader: React.FC<FlowHeaderProps> = ({ title, onBack }) => {
    const styles = useFlowStyles();

    return (
        <Box sx={styles.header}>
            {onBack && (
                <IconButton
                    onClick={onBack}
                    sx={styles.backButton}
                    size="small"
                >
                    <ArrowBackIcon />
                </IconButton>
            )}
            <Typography variant="h6" sx={styles.headerTitle}>
                {title}
            </Typography>
        </Box>
    );
}; 