// const authenticationServer = "localhost";
// const authenticationServer_azure = "https://authentication-service.gentleplant-8c3ed038.australiaeast.azurecontainerapps.io";
// const portAuth = "1111";
// const AUTH_API_URL_LOCAL = `http://${authenticationServer}:${portAuth}`;
// const AZURE_WINDOWS_SERVER = 'https://demo.xarcam.ai';
// const AZURE_WINDOWS_SERVER_AND_PORT_AUTH = `${AZURE_WINDOWS_SERVER}:${portAuth}`;

// const AUTH_API_URL_AZURE = `${authenticationServer_azure}`;
// export const AUTH_API_URL = AZURE_WINDOWS_SERVER_AND_PORT_AUTH;//AUTH_API_URL_LOCAL////;

// const chatGptServer = "localhost";
// const thiredPartyServer_auzure = "https://thiredpartyservice.gentleplant-8c3ed038.australiaeast.azurecontainerapps.io";
// const portChatGpt = "1112";

// const CHATGPT_API_LOCAL = `http://${chatGptServer}:${portChatGpt}`;
// const CHATGPT_API_AZURE = `${thiredPartyServer_auzure}`;
// const AZURE_WINDOWS_SERVER_AND_PORT_THIRD = `${AZURE_WINDOWS_SERVER}:${portChatGpt}`;



// export const CHATGPT_API_URL = AZURE_WINDOWS_SERVER_AND_PORT_THIRD;//CHATGPT_API_LOCAL//

// const portGateway = "11100";
// const localhost = "http://localhost";
// // const LOCAL_GATEWAY = `${AZURE_WINDOWS_SERVER}:${portGateway}`;
// const LOCAL_GATEWAY = `${AZURE_WINDOWS_SERVER}`;
// export const GATEWAY_API_URL = LOCAL_GATEWAY;

// Detect the current domain
const currentDomain = window.location.hostname;

const isLocal = currentDomain === "localhost";
const isDemoXarcam = currentDomain === "demo.xarcam.ai";
const isSaritAI = currentDomain === "ld.sarit.ai";

// Define base URLs based on the domain
const BASE_URL_DEMO_XARCAM = "https://demo.xarcam.ai";
const BASE_URL_SARIT_AI = "https://ld.sarit.ai";

// Authentication API
const LOCAL_AUTH_API_URL = `http://localhost:1111`;
const AUTH_API_URL_AZURE = "https://authentication-service.gentleplant-8c3ed038.australiaeast.azurecontainerapps.io";

export const AUTH_API_URL = isLocal
    ? LOCAL_AUTH_API_URL
    : isDemoXarcam
    ? AUTH_API_URL_AZURE
    : AUTH_API_URL_AZURE; // Adjust if different for Sarit

// Third Party API (ChatGPT)
const LOCAL_CHATGPT_API_URL = `http://localhost:1112`;
const CHATGPT_API_URL_AZURE = "https://thiredpartyservice.gentleplant-8c3ed038.australiaeast.azurecontainerapps.io";

export const CHATGPT_API_URL = isLocal
    ? LOCAL_CHATGPT_API_URL
    : isDemoXarcam
    ? CHATGPT_API_URL_AZURE
    : CHATGPT_API_URL_AZURE; // Adjust if different for Sarit

// Gateway API
const LOCAL_GATEWAY_API_URL = "http://localhost:11100";
const GATEWAY_API_URL_DEMO_XARCAM = BASE_URL_DEMO_XARCAM;
const GATEWAY_API_URL_SARIT_AI = BASE_URL_SARIT_AI;

export const GATEWAY_API_URL = isLocal
    ? LOCAL_GATEWAY_API_URL
    : isDemoXarcam
    ? GATEWAY_API_URL_DEMO_XARCAM
    : GATEWAY_API_URL_SARIT_AI;