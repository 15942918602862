import { Node, Edge } from 'reactflow';

interface ParsedFlow {
    nodes: Node[];
    edges: Edge[];
}

export const parseMermaidToFlow = (mermaidFlow: string): ParsedFlow => {
    const nodes: Node[] = [];
    const edges: Edge[] = [];
    const nodeMap = new Map<string, number>();

    // Split the flow into lines and remove empty lines
    const lines = mermaidFlow.split('\n').filter(line => line.trim());

    // Skip the first line (graph TD)
    for (let i = 1; i < lines.length; i++) {
        const line = lines[i].trim();
        if (!line) continue;

        // Parse node or edge
        const match = line.match(/([A-Z])\[(.*?)\](?:\s*-->\s*([A-Z])\[(.*?)\])?/);
        if (!match) continue;

        const [, sourceId, sourceLabel, targetId, targetLabel] = match;

        // Add source node if it doesn't exist
        if (!nodeMap.has(sourceId)) {
            nodes.push({
                id: sourceId,
                data: { label: sourceLabel },
                position: { x: 0, y: 0 }, // Position will be calculated by layout
                type: 'default',
            });
            nodeMap.set(sourceId, nodes.length - 1);
        }

        // If there's a target node, add it and create an edge
        if (targetId && targetLabel) {
            if (!nodeMap.has(targetId)) {
                nodes.push({
                    id: targetId,
                    data: { label: targetLabel },
                    position: { x: 0, y: 0 },
                    type: 'default',
                });
                nodeMap.set(targetId, nodes.length - 1);
            }

            edges.push({
                id: `${sourceId}-${targetId}`,
                source: sourceId,
                target: targetId,
                type: 'smoothstep',
            });
        }
    }

    // Calculate positions for nodes
    const nodeCount = nodes.length;
    const spacing = 200;
    const radius = (nodeCount * spacing) / (2 * Math.PI);

    nodes.forEach((node, index) => {
        const angle = (index * 2 * Math.PI) / nodeCount;
        node.position = {
            x: radius * Math.cos(angle),
            y: radius * Math.sin(angle),
        };
    });

    return { nodes, edges };
}; 