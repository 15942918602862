import axiosInstance from "../interceptor";
import { CHATGPT_API_URL, GATEWAY_API_URL } from "../api";

const API = '/api/whiteLabelService/api/v1/customersWhiteLabel';

export const getDataByDomain = async (domain: string) => {
    return axiosInstance.get(`${GATEWAY_API_URL}${API}/getDataByDomain/${domain}`);
}


