import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useFlowStyles } from '../styles/useFlowStyles';

interface FlowDescriptionProps {
    description: string;
}

export const FlowDescription: React.FC<FlowDescriptionProps> = ({ description }) => {
    const styles = useFlowStyles();

    return (
        <Paper sx={styles.descriptionContainer} elevation={3}>
            <Typography variant="body2" sx={styles.description}>
                {description}
            </Typography>
        </Paper>
    );
}; 