import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useFlowStyles } from '../styles/useFlowStyles';

interface SubFlowSelectorProps {
    currentFlow: {
        id: number;
        header: string;
        subFlowMapping: Record<string, number>;
    };
    subFlows: Array<{
        id: number;
        header: string;
    }>;
    onSubFlowSelect: (subFlow: { id: number; header: string }) => void;
}

export const SubFlowSelector: React.FC<SubFlowSelectorProps> = ({
    currentFlow,
    subFlows,
    onSubFlowSelect,
}) => {
    const styles = useFlowStyles();

    // Get available sub-flows for the current flow
    const availableSubFlows = subFlows.filter(subFlow =>
        Object.values(currentFlow.subFlowMapping).includes(subFlow.id)
    );

    if (availableSubFlows.length === 0) {
        return null;
    }

    return (
        <Box sx={styles.subFlowSelector}>
            <Typography variant="subtitle2" sx={styles.subFlowTitle}>
                Sub-Flows
            </Typography>
            <Box sx={styles.subFlowButtons}>
                {availableSubFlows.map(subFlow => (
                    <Button
                        key={subFlow.id}
                        variant="outlined"
                        size="small"
                        onClick={() => onSubFlowSelect(subFlow)}
                        sx={styles.subFlowButton}
                    >
                        {subFlow.header}
                    </Button>
                ))}
            </Box>
        </Box>
    );
}; 