import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getAllCourses, getCourseContent, getCourseMovieData, getTocByCourseId, getCourseMovieDataWithNewBreakDown, getAllTOCManagementData, getCourseSpecificTopicsKeyPointsAndSentences } from '../api/serverApis/coursesApi';

export interface combinationSearchState {
  courseSpecificTopicsKeyPointsAndSentences: any[];
  tocManagementData: any[];
  data: any;
  courses: any[];
  contentOfCourse: any[];
  courseMovieData: any;
  selectedVideo: any;
  loading: boolean;
  error: string | null | undefined;
}

const initialState: combinationSearchState = {
  courseSpecificTopicsKeyPointsAndSentences: [],
  tocManagementData: [],
  data: null,
  contentOfCourse: [],
  selectedVideo: null,
  courseMovieData: null,
  courses: [],
  loading: false,
  error: null,
};

export const fetchAllCourses = createAsyncThunk('courses/fetchAllCourses', async () => {
  const response = await getAllCourses();
  return response.data;
});

export const fetchContentOfCourse = createAsyncThunk('courses/fetchContentOfCourse', async (courseId: string) => {
  const response = await getCourseContent(courseId);
  return response.data;
});

// export const fetchContentOfCourse = createAsyncThunk('courses/fetchContentOfCourse', async (courseId: string) => {
//   const response = await getTocByCourseId(courseId);
//   return response.data;
// });

export const fetchCourseTOCManagementData = createAsyncThunk('courses/fetchCourseTOCManagementData', async (courseId: string) => {
  const response = await getAllTOCManagementData(courseId);
  return response.data;
});

export const fetchCourseMovieData = createAsyncThunk('courses/fetchCourseMovieData', async ({ movieId, id }: { movieId: string, id: string }) => {
  const response = await getCourseMovieData(movieId, id);
  return response.data;
});

export const fetchCourseMovieDataWithNewBreakDown = createAsyncThunk('courses/fetchCourseMovieDataWithNewBreakDown', async ({ movieId, id }: { movieId: string, id: string }) => {
  const response = await getCourseMovieDataWithNewBreakDown(movieId, id);
  return response.data;
});

export const fetchCourseSpecificTopicsKeyPointsAndSentences = createAsyncThunk('courses/fetchCourseSpecificTopicsKeyPointsAndSentences', async ({ movieId, topicId }: { movieId: string, topicId: string }) => {
  const response = await getCourseSpecificTopicsKeyPointsAndSentences(movieId, topicId);
  return response.data;
});


const handleState = (action: any) => {
  return (builder: any) => {
    builder
      .addCase(action.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(action.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.loading = false;
        if (action.type === 'courses/fetchAllCourses/fulfilled') {
          state.courses = action.payload;
        }
        if (action.type === 'courses/fetchContentOfCourse/fulfilled') {
          state.contentOfCourse = action.payload;
        }
        if (action.type === 'courses/fetchCourseMovieData/fulfilled') {
          state.courseMovieData = action.payload;
        }
        if (action.type === 'courses/fetchCourseMovieDataWithNewBreakDown/fulfilled') {
          state.courseMovieData = action.payload;
        }
        if (action.type === 'courses/fetchCourseTOCManagementData/fulfilled') {
          state.tocManagementData = action.payload;
        }
        if (action.type === 'courses/fetchCourseSpecificTopicsKeyPointsAndSentences/fulfilled') {
          state.courseSpecificTopicsKeyPointsAndSentences = action.payload;
        }
      })
      .addCase(action.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      });
  };
}

const coursesSlice = createSlice({
  name: 'courses',
  initialState: initialState,
  reducers: {
    setSelectedVideoDetails: (state, action: PayloadAction<{ movieId: string, id: string, courseId: string }>) => {
      state.selectedVideo = action.payload;
    },
    resetSelectedVideo: (state) => {
      state.selectedVideo = null;
    },
    resetCourseMovieData: (state) => {
      state.courseMovieData = null;
    },
    resetContentOfCourse: (state) => {
      state.contentOfCourse = [];
    }
  },
  extraReducers: (builder) => {
    handleState(fetchAllCourses)(builder);
    handleState(fetchContentOfCourse)(builder);
    handleState(fetchCourseMovieData)(builder);
    handleState(fetchCourseMovieDataWithNewBreakDown)(builder);
    handleState(fetchCourseTOCManagementData)(builder);
    handleState(fetchCourseSpecificTopicsKeyPointsAndSentences)(builder);
  }
});
export const { setSelectedVideoDetails, resetSelectedVideo, resetContentOfCourse, resetCourseMovieData } = coursesSlice.actions;
export default coursesSlice.reducer;