import { useEffect } from 'react';
import { getDataByDomain } from '../../api/serverApis/whiteLabelApi';

const useDynamicFaviconAndTitle = () => {
    useEffect(() => {
        const domain = window.location.hostname;

        getDataByDomain(domain).then((res: any) => {
            const { faviconName, text2 } = res.data;

            const faviconPath = `${faviconName}`;

            // Update Favicon
            let faviconElement = document.querySelector("link[rel~='icon']") as HTMLLinkElement | null;
            if (!faviconElement) {
                faviconElement = document.createElement('link');
                faviconElement.rel = 'icon';
                document.head.appendChild(faviconElement);
            }
            faviconElement.href = faviconPath;

            // Update Title
            if (text2) {
                document.title = text2;
            }

        }).catch((error) => {
            console.error('Failed to set favicon or title:', error);
        });
    }, []);
};

export default useDynamicFaviconAndTitle;
