import React, { useState, useEffect, useRef } from 'react';
import VideoSemanticForSearch from './VideoSemanticForSearch';
import VideoAnswerForSearch from './VideoAnswerForSearch';

import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import { fetchSearchDeleteMovie } from '../../../../features/combinationSearchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../../store';
import { InputAdornment, TextField, Select, MenuItem, Grid, Box, Accordion, AccordionSummary, AccordionDetails, Typography, Tooltip, IconButton, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Button, CircularProgress, Menu, Popover } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Index, Semantic } from '../../configuration/Interface';
import DynamicTable from '../../../generalComponents/DynamicTable';
import VideoText from '../../VideoText';
import LoadingDots from '../LoadingDots';
import { createMonitoringOpenQuestion, createMonitoringDeleteAllQuestion, getMonitoringMaxByCourseIdAndDomain, getMonitoringUserCourseDomainMaxQuestion, resetTheNumberAtTheBeginningOfAMonthAccordingToTheFirstDay, createMonitoringLikedDislikedAllQuestion } from '../../../../api/serverApis/monitoringApi';
import { addToGlobalFAQ, addToPrivateFAQ, removeFromGlobalFAQ, removeFromPrivateFAQ } from '../../../../api/serverApis/combinationSearchApi';
import { getAllGlobalFAQData, getSearchByCourseId } from '../../../../features/combinationSearchSlice';
import { getUsers } from '../../../../api/serverApis/userApi';
import { SelectChangeEvent } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { addLikeToQuestion } from '../../../../api/serverApis/combinationSearchApi';
import MenuIcon from '@mui/icons-material/Menu';

interface User {
    userId: string;
    email: string;
}
interface MainSearchProps {
    data: any;
    theme: any;
    resultOfOpeningAskQuestion: any;
    handleMainSearchFullScreen: any;
    currentView: string;
    courseId: string;
    setHasInteracted: any;
    hasInteracted: boolean;
    setIsWaitingForNewQuestion: any,
    isWaitingForNewQuestion: boolean
}

const MainSearchForSearchOnly: React.FC<MainSearchProps> = ({ courseId, handleMainSearchFullScreen, currentView, data, theme, resultOfOpeningAskQuestion, setHasInteracted, hasInteracted, setIsWaitingForNewQuestion, isWaitingForNewQuestion }) => {

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<any | null>(null);
    const [fullScreenItem, setFullScreenItem] = useState<any>(null);
    const dispatch = useDispatch<AppDispatch>();
    const [maxQuestions, setMaxQuestions] = useState<number | null>(null);
    const [remainingQuestions, setRemainingQuestions] = useState<number | null>(null);
    const [starredQuestions, setStarredQuestions] = useState<string[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [differentUserId, setDifferentUserId] = useState('');
    const [permissions, setPermissions] = useState<any>(null);
    const [searchTerm, setSearchTerm] = useState(''); // For search input
    const [likeStatus, setLikeStatus] = useState<{ [key: string]: 'liked' | 'disliked' | null }>({});
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const isHebrewOrArabic = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF\u0600-\u06FF]/.test(text);
    const sortedAnswerData = data && data.answerData
        ? [...data.answerData].sort((a: any, b: any) => {
            const dateA = a.answer?.date ? new Date(a.answer.date).getTime() : 0;
            const dateB = b.answer?.date ? new Date(b.answer.date).getTime() : 0;
            return dateB - dateA;
        })
        : [];

    const filteredData = searchTerm ? sortedAnswerData.filter((item: any) => item.answer?.question?.toLowerCase().includes(searchTerm.toLowerCase())) : sortedAnswerData;


    /// ###### useEffects ###### ///
    useEffect(() => {
        // Retrieve permissions from localStorage
        const permissions = JSON.parse(localStorage.getItem('permissions') || '{}');

        // Check if the user has access to usersList in MainSearch
        const canAccessUsersList = permissions?.MainSearch?.usersList?.canAccess;

        // Fetch users only if canAccess is true
        if (canAccessUsersList) {
            const fetchUsers = async () => {
                try {
                    const response = await getUsers();
                    const sortedUsers = response.data.sort((a: any, b: any) => {

                        return a.email.localeCompare(b.email);
                    });
                    setUsers(sortedUsers); // Assume response.data contains an array of users
                } catch (error) {
                    console.error('Error fetching users:', error);
                }
            };
            fetchUsers();
        }
    }, []);

    useEffect(() => {
        const savedPermissions = localStorage.getItem('permissions');
        if (savedPermissions) {
            setPermissions(JSON.parse(savedPermissions));
        }
    }, []);

    useEffect(() => {
        const starred = Array.isArray(data?.answerData)
            ? data?.answerData.filter((item: any) => item.faqAddToGlobal === true).map((item: any) => item.answerDataId)
            : [];
        setStarredQuestions(starred);
    }, [data]);

    // useEffect(() => {
    //     const fetchLatestAnswer = async () => {
    //         if (data?.answerData?.length > 0) {

    //             if (filteredData[0].answer.answer === "Waiting for an answer...") {
    //                 const firstItem = filteredData[0];
    //                 await openingAskQuestion(firstItem.searchId, firstItem.answerDataId);
    //                 setSelectedItem(firstItem);
    //             }
    //             // if (filteredData[filteredData.length - 1].answer.answer !== "Waiting for an answer...") {
    //             //     setIsWaitingForNewQuestion(false);
    //             // }
    //         }
    //         if (selectedItem && filteredData) {
    //             // Find the updated item in the new data.
    //             const updatedItem = filteredData.find(
    //                 (item: any) => item.answerDataId === selectedItem.answerDataId
    //             );
    //             // If we found an updated version that differs from the current selectedItem,
    //             // update the state.
    //             if (updatedItem && updatedItem !== selectedItem) {
    //                 setSelectedItem(updatedItem);
    //             }
    //         }
    //     }
    //     fetchLatestAnswer();
    // }, [data?.answerData, selectedItem]);
    useEffect(() => {
        let isMounted = true; // To prevent state updates on unmounted component

        const fetchLatestAnswer = async () => {
            if (data?.answerData?.length > 0) {
                const firstItem = filteredData[0];

                // Check if the first item's answer is "Waiting for an answer..."
                if (firstItem.answer.answer === "Waiting for an answer...") {
                    setIsWaitingForNewQuestion(true); // Ensure LoadingDots is displayed

                    // Only call API if selectedItem is different or it's not already processing
                    if (!selectedItem || selectedItem.answerDataId !== firstItem.answerDataId) {
                        await openingAskQuestion(firstItem.searchId, firstItem.answerDataId);

                        if (isMounted) {
                            setSelectedItem(firstItem); // Update selection safely
                        }
                    }
                } else {
                    setIsWaitingForNewQuestion(false); // Stop showing LoadingDots once an answer arrives
                }
            }
        };

        fetchLatestAnswer();

        return () => {
            isMounted = false; // Cleanup to prevent unwanted re-renders
        };
    }, [data?.answerData]);
    useEffect(() => {
        if (selectedItem && filteredData) {
            // Find the updated item in the new data.
            const updatedItem = filteredData.find(
                (item: any) => item.answerDataId === selectedItem.answerDataId
            );
            // If we found an updated version that differs from the current selectedItem,
            // update the state.
            if (updatedItem && updatedItem !== selectedItem) {
                setSelectedItem(updatedItem);
            }
        }
    }, [data?.answerData, selectedItem]);
    useEffect(() => {
        if (courseId) {
            // Fetch the max questions allowed and remaining questions
            fetchMaxAndRemainingQuestions(courseId);
        }
    }, [data?.answerData]);

    const fetchMaxAndRemainingQuestions = async (courseId: string) => {
        try {
            await resetTheNumberAtTheBeginningOfAMonthAccordingToTheFirstDay(courseId);
            // Fetch max questions allowed for this course
            const maxQuestionsResponse = await getMonitoringMaxByCourseIdAndDomain(courseId);
            setMaxQuestions(maxQuestionsResponse?.data?.result || 0);

            // Fetch how many questions the user can still ask
            const remainingQuestionsResponse = await getMonitoringUserCourseDomainMaxQuestion(courseId);
            setRemainingQuestions(remainingQuestionsResponse?.data?.result || 0);
        } catch (error) {
            console.error('Error fetching max/remaining questions:', error);
        }
    };

    /// ######## handels ######## ///
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLike = (answerDataId: string, item: any) => {
        addLikeToQuestion(courseId, data.searchId, answerDataId, 'liked');
        setLikeStatus((prev) => ({ ...prev, [answerDataId]: 'liked' }));
        createMonitoringLikedDislikedAllQuestion(courseId, data.searchId, answerDataId, item.answer.question, 'liked');
    };
    const handleDislike = (answerDataId: string, item: any) => {
        addLikeToQuestion(courseId, data.searchId, answerDataId, 'disliked');
        setLikeStatus((prev) => ({ ...prev, [answerDataId]: 'disliked' }));
        createMonitoringLikedDislikedAllQuestion(courseId, data.searchId, answerDataId, item.answer.question, 'disliked');
    };
    const handleMovieDelete = () => {
        const item = deleteConfirmOpen;
        dispatch(fetchSearchDeleteMovie({ courseId: data.courseId, searchId: data.searchId, answerDataId: item?.answerDataId }));
        createMonitoringDeleteAllQuestion(data.courseId, data.searchId, item.answerDataId, item.answer.question);
        setDeleteConfirmOpen(null);
    };
    const handleUserChange = (event: SelectChangeEvent<string>) => {
        const selectedUserId = event.target.value as string;
        setSelectedUser(event.target.value as string);
        // Additional logic for selected user can be added here
        if (selectedUserId && courseId) {
            setDifferentUserId(selectedUserId);
            dispatch(getSearchByCourseId({ userId: selectedUserId, courseId: courseId, searchId: "first0001" }));
        }
    };
    const handleStarClick = async (answerDataId: string) => {
        try {
            // Check if the question is currently starred
            const isCurrentlyStarred = starredQuestions.includes(answerDataId);

            // Toggle star locally
            setStarredQuestions(prev =>
                isCurrentlyStarred ? prev.filter(id => id !== answerDataId) : [...prev, answerDataId]
            );

            // Send request to server to update star status
            if (isCurrentlyStarred) {
                await removeFromGlobalFAQ(answerDataId, data.userId, data.courseId, data.searchId);
            } else {
                await addToGlobalFAQ(answerDataId, data.userId, data.courseId, data.searchId);
            }
        } catch (error) {
            console.error("Error toggling star status:", error);
        }
        dispatch(getAllGlobalFAQData({ userId: null, courseId: courseId, searchId: "first0001", answerDataId: null }));
    };
    const handleListItemClick = async (item: any, isWaiting: boolean) => {
        setIsWaitingForNewQuestion(isWaiting);
        setSelectedItem(item);
        setHasInteracted(true); // Move search bar down
        await openingAskQuestion(item.searchId, item.answerDataId);
    };
    const openingAskQuestion = async (searchId: string, answerDataId: string) => {
        await resultOfOpeningAskQuestion(searchId, answerDataId, differentUserId);
    };




    ///// ##### renders ###### /////
    const renderTopControls = () => (
        <Box sx={{ width: '100%' }}>
            {!hasInteracted ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '2%',
                        right: '2%',
                        width: '31%', // Align with renderListPanel
                        display: 'flex',
                        justifyContent: 'flex-end', // Move to the right
                        alignItems: 'center',
                        backgroundColor: theme.palette.background.paper,
                        padding: '10px 0',
                        zIndex: 1000,
                        marginRight: '3%' // Aligns it with the right panel
                    }}
                >
                    <TextField
                        label="Filter your questions"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        variant="outlined"
                        sx={{ width: '90%' }}
                        placeholder="Search..."
                        InputProps={{
                            endAdornment: searchTerm && (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setSearchTerm('')}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            ) : (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 55,
                        left: 0,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: theme.palette.background.paper,
                        padding: '10px 20px',
                        zIndex: 1000,
                    }}
                >
                    {/* Left Side Controls */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Button
                            variant="contained"
                            onClick={handleClick}
                            startIcon={<MenuIcon />}
                            sx={{
                                backgroundColor: theme.palette.common.white,
                                color: theme.palette.common.black,
                                border: `1px solid ${theme.palette.common.black}`,
                                textTransform: 'none',
                                '&:hover': { backgroundColor: theme.palette.grey[200] },
                            }}
                        >

                        </Button>

                        <Popover
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                            sx={{ mt: 1 }}
                        >
                            <Box sx={{ p: 2, width: 250 }}>
                                {permissions?.MainSearch?.usersList?.canAccess && (
                                    <Select
                                        value={selectedUser}
                                        onChange={handleUserChange}
                                        displayEmpty
                                        sx={{ width: '100%', mb: 2 }}
                                    >
                                        <MenuItem value="" disabled>Select User</MenuItem>
                                        {users.map((user) => (
                                            <MenuItem key={user.userId} value={user.userId}>
                                                {user.email}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            </Box>
                        </Popover>

                        {maxQuestions && remainingQuestions && (
                            <Tooltip
                                title={`You can ask ${maxQuestions - remainingQuestions} more questions out of ${maxQuestions}.`}
                            >
                                <Box
                                    sx={{

                                        padding: '4px 20px',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                        color: 'black',
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                    }}
                                >
                                    <Typography variant="h6">{remainingQuestions}/{maxQuestions}</Typography>
                                </Box>
                            </Tooltip>
                        )}
                    </Box>

                    {/* Right Side - Search Filter */}
                    <TextField
                        label="Filter your questions"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        variant="outlined"
                        sx={{ marginTop: '1%', marginRight: '3%', width: '30%' }}
                        placeholder="Search..."
                        InputProps={{
                            endAdornment: searchTerm && (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setSearchTerm('')}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            )}
        </Box>
    );
    // Helper method: Left detail panel showing the selected item's details.
    const renderDetailPanel = () => (
        <Grid item xs={8} sx={{
            maxHeight: 'calc(100vh - 120px)',
            overflowY: 'auto',
            paddingRight: '10px',
            marginTop: '-2%'
        }}>
            <Box sx={{ p: 2 }}>
                {selectedItem?.answer?.answer === "Waiting for an answer..." ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30%' }}>
                        <LoadingDots
                            messages={[
                                "Let's first understand what you're asking",
                                "We're carefully processing the details",
                                "Now, we're diving deep to analyze your question",
                                "Checking the best possible sources for your answer",
                                "Ensuring accuracy as we gather data",
                                "Almost there, compiling everything for you",
                                "Hang tight, we're fetching exactly what you need",
                                "Your answer is just moments away"
                            ]}
                        />
                    </Box>
                ) : selectedItem ? (
                    <Box>
                        <VideoAnswerForSearch
                            item={selectedItem.answer}
                            data={data}
                            answerDataId={selectedItem.answerDataId}
                        />
                        {selectedItem.movies &&
                            selectedItem.movies.map((movie: any, mIndex: number) => (
                                <React.Fragment key={mIndex}>
                                    {movie.semantic &&
                                        movie.semantic.map((semantic: Semantic, sIndex: number) => (
                                            <Box sx={{ mt: 2 }} key={sIndex}>
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        direction: isHebrewOrArabic(selectedItem.answer.question)
                                                            ? 'rtl'
                                                            : 'ltr',
                                                    }}
                                                >
                                                    {isHebrewOrArabic(selectedItem.answer.question)
                                                        ? `תימוכין ${sIndex + 1}:`
                                                        : `Reference ${sIndex + 1}:`}
                                                </Typography>
                                                <VideoSemanticForSearch
                                                    dataAnswerId={selectedItem.answerDataId}
                                                    data={semantic}
                                                    isOpen={false}
                                                />
                                            </Box>
                                        ))}
                                </React.Fragment>
                            ))}
                    </Box>
                ) : (
                    null
                )}
            </Box>
        </Grid>
    );
    // Helper method: Right list panel showing the headers (like the accordion headers).
    const renderListPanel = () => (
        <Box
            sx={{
                position: 'sticky',
                top: 97,
                maxHeight: '82vh',
                overflowY: 'auto',
                p: 1,
                // border: `1px solid ${theme.palette.divider}`,
                // borderRadius: '8px',
                width: '30%'
            }}
        >
            {filteredData &&
                filteredData.map((item: any, index: number) => (
                    <Box
                        key={index}
                        onClick={async () => await handleListItemClick(item, false)}
                        sx={{
                            borderBottom: '1px solid #ddd',
                            p: 1,
                            cursor: 'pointer',
                            backgroundColor:
                                fullScreenItem === item.answerDataId ? 'rgba(0, 123, 255, 0.1)' : 'inherit',
                            '&:hover': { backgroundColor: 'rgba(0, 123, 255, 0.1)' },
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Tooltip title={item?.answer?.question || ''} placement="top">
                                <Typography noWrap sx={{ fontWeight: 'bold' }}>

                                    {item?.answer?.question
                                        ? item.answer.question.length > 40
                                            ? `${item.answer.question.slice(0, 40)}...`
                                            : item.answer.question
                                        : 'No question'}
                                </Typography>
                            </Tooltip>
                            {permissions?.MainSearch?.star?.canAccess && (
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleStarClick(item.answerDataId);
                                    }}
                                >
                                    <StarIcon
                                        sx={{
                                            color: starredQuestions.includes(item.answerDataId) ? 'gold' : 'gray',
                                        }}
                                    />
                                </IconButton>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                            <Tooltip
                                title={
                                    item?.answer?.date
                                        ? new Date(item.answer.date).toLocaleTimeString('en-GB')
                                        : ''
                                }
                                placement="top"
                            >
                                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                    {item?.answer?.date ? new Date(item.answer.date).toLocaleDateString() : ''}
                                </Typography>
                            </Tooltip>
                            <Tooltip title="Delete this section" placement="top">
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDeleteConfirmOpen(item);
                                    }}
                                >
                                    <DeleteIcon
                                        sx={{
                                            fontSize: '20px',
                                            color: theme.palette.mode === 'dark' ? '#e0ebeb' : 'gray',
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <React.Fragment>
                            <Dialog
                                open={deleteConfirmOpen ? true : false}
                                onClose={() => setDeleteConfirmOpen(null)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                key={index}
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {"Confirm Deletion"}
                                </DialogTitle>
                                <DialogContent key={index}>
                                    <DialogContentText id="alert-dialog-description" key={index}>
                                        Are you sure you want to Remove this section?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions key={index}>
                                    <Button onClick={() => setDeleteConfirmOpen(null)}>Cancel</Button>
                                    <Button key={index} onClick={handleMovieDelete} autoFocus>
                                        Delete
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </React.Fragment>
                    </Box>
                ))}
        </Box>
    );
    // Final return: assemble the top controls and two-column layout.
    return (
        <Box
            sx={{
                width: '100%',
                paddingBottom: '100px',
                overflow: 'hidden',
            }}
        >
            {renderTopControls()}
            <Grid container spacing={0} sx={{ height: 'calc(100vh - 60px)' }}>
                {renderDetailPanel()}
                {renderListPanel()}
            </Grid>
        </Box>
    );

}

export default MainSearchForSearchOnly;
